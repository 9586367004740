import http from "./axios/http";


export function getReachGameUrl(thirdPartyCode, gameCode, platform) {
    return http.get('/casino/reach/get_open_url?thirdPartyCode=' + thirdPartyCode + '&gameCode=' + gameCode + '&platform=' + platform + '&t=' + new Date().getTime())
}

export function getThirdPartyGameList(thirdPartyCode) {
    return http.get('/casino/reach/get_third_party_game_list?thirdPartyCode=' + thirdPartyCode + '&t=' + new Date().getTime())
}

export function getReachBetList(pageNum, pageSize) {
    return http.get('/casino/reach/get_reach_bet_list?t=' + new Date().getTime() + '&pageNum=' + '&pageSize=' + pageSize)
}

export function deleteAllReachBet() {
    return http.get('/casino/reach/delete_all?t=' + new Date().getTime())
}






